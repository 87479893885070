import styled from 'styled-components';
import tw from 'tailwind.macro';

interface StyledProps {
  bggray: boolean;
}

export const Banner = styled.section<StyledProps>`
  ${tw`border-b border-indigo-100`};
  ${({ bggray }) => bggray ? `background-color: #FFFFB0` : tw`bg-white`};
`;

export const BannerText = styled.div`
  ${tw`sm:w-1/2 w-full m-auto px-4`};
`;

export const BannerTitle = styled.h2`
  ${tw`mb-0 sm:text-3xl text-3xl font-bold sm:text-left text-center`};
`;

export const Content = styled.p`
  ${tw`mb-4 px-20`};
`;

export const Image = styled.figure`
  ${tw`mx-auto sm:w-1/2 w-full`};
`;
