import React from 'react';
import Img, { FixedObject, FluidObject } from 'gatsby-image';

import Container from 'components/ui/Container';

import * as Styled from './styles';
import FormatHtml from 'components/utils/FormatHtml';

interface Props {
  title: string;
  content: React.ReactNode;
  appImage: FluidObject;
  altImageText: string;
  imageLeftOnDesktop: boolean;
  bggray: boolean;
}

const FeatureSection: React.FC<Props> = ({ title, content, appImage, altImageText, imageLeftOnDesktop=false, bggray=false }) => (
  <Styled.Banner bggray={bggray}>
      {renderForScreenSize(title, content, appImage, altImageText, imageLeftOnDesktop)}
  </Styled.Banner>
);

function renderForScreenSize(title: string, content: React.ReactNode, appImage: FluidObject, 
  altImageText: string, imageLeftOnDesktop: boolean) {
  if ((typeof window !== `undefined` && isSmall()) || 
  (typeof window !== `undefined` && !isSmall() && !imageLeftOnDesktop)) {
    return (
      <Container section>
        <Styled.BannerText>
          <Styled.BannerTitle>{title}</Styled.BannerTitle>
          <FormatHtml content={content} />
        </Styled.BannerText>
        <Styled.Image><Img fluid={appImage} alt={altImageText}/></Styled.Image>
      </Container>
    );
  } else {
    return (
      <Container section>
        <Styled.Image><Img fluid={appImage} alt={altImageText}/></Styled.Image>
        <Styled.BannerText>
          <Styled.BannerTitle>{title}</Styled.BannerTitle>
          <FormatHtml content={content} />
        </Styled.BannerText>
      </Container>
    );
  }
}

function isSmall() {
  if(typeof window !== undefined && window.innerWidth < 768) {
      return true;
    }
  return false;
};

export default FeatureSection;
