import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';

import * as Styled from './styles';
import Button from 'components/ui/Button';

interface PartnerType {
  node: {
    id: string;
    frontmatter: {
      title: string;
      subtitle: string;
      buttonText: string;
      partnerType: string;
    };
  };
}

const YourTeamBreakdown: React.FC = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "partnertypes" } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              subtitle
              buttonText
              partnerType
            }
          }
        }
      }
    }
  `);

  const partnerTypes: PartnerType[] = allMarkdownRemark.edges;

  return (
    <Styled.Banner>
      <Container>
        <Styled.PartnerTypes>
            {partnerTypes.map((item) => {
              const {
                id,
                frontmatter: { 
                  title,
                  subtitle,
                  buttonText,
                  partnerType
                }
              } = item.node;

              return (
                <Styled.PartnerType key={id}>
                  <Styled.Title>{title}</Styled.Title>
                  <Styled.Subtitle>{subtitle}</Styled.Subtitle>
                  <Button primary block to={`/finda${partnerType}`}>
            {buttonText}
          </Button>
                </Styled.PartnerType>
              );
            })}
        </Styled.PartnerTypes>
      </Container>
    </Styled.Banner>

  );
};

export default YourTeamBreakdown;
