import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Post = styled.section`
  ${tw`bg-white w-full border-b border-indigo-100`};
`;

export const HeaderText = styled.h2`
  ${tw`mb-4 pt-4 sm:text-l w-full text-xl font-bold text-center`};
`;

export const EmbeddedPost = styled.div`
  ${tw`mx-auto px-8 items-center justify-center w-full	`};
`;
