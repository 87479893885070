import styled from 'styled-components';
import tw from 'tailwind.macro';

export const PartnerTypes = styled.div`
  ${tw`mx-auto md:flex items-center justify-center w-full`};
`;

export const Banner = styled.section`
  ${tw`bg-white border-b border-indigo-100`};
`;

export const PartnerType = styled.div`
  ${tw`flex flex-col items-center text-center md:w-1/3`};
`;

export const Image = styled.figure`
  ${tw`w-16 h-16 mx-auto border border-teal-400 rounded-full`};

  img {
    ${tw`border-4 border-white rounded-full`};
  }
`;

export const Subtitle = styled.h3`
  ${tw`mb-0 mt-0 mx-2`};
`;

export const Title = styled.h2`
  ${tw`text-2xl font-bold w-full text-center mb-0 mt-2`};
`;

