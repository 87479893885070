import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HeroBanner from 'components/HeroBanner';
// import Testimonials from 'components/Testimonials';
import InstagramPost from 'components/ui/Instagram';
import CalculatorFeatureSection from 'components/FeatureSection/Features/Calculator';
import StepsFeatureSection from 'components/FeatureSection/Features/StepByStepGuide';
// import LiveRatesFeatureSection from 'components/FeatureSection/Features/LiveRates';
import TeamFeatureSection from 'components/FeatureSection/Features/YourTeam';
import YourTeamBreakdown from 'components/FeatureSection/Features/YourTeamBreakdown';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Your Home Buying Community" />
      <HeroBanner />
      <hr />
      <CalculatorFeatureSection />
      <StepsFeatureSection />
      {/* <LiveRatesFeatureSection /> */}
      <InstagramPost />
      <TeamFeatureSection />
      <YourTeamBreakdown />
      {/* <Testimonials /> */}

    </Layout>
  );
};

export default IndexPage;
