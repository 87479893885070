import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'components/ui/Banner';

import { SectionTitle } from 'helpers/definitions';
import { FluidObject } from 'gatsby-image';

interface SectionHeroBanner extends SectionTitle {
  content: string;
  callToAction: string;
  callToActionSubtitle: string;
  linkTo: string;
  linkText: string;
}

const HeroBanner: React.FC = () => {
  const { markdownRemark, file } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "hero section" } }) {
        frontmatter {
          title
          subtitle
          content
          callToAction
          callToActionSubtitle
          linkTo
          linkText
        }
      }
      file(relativePath: { eq: "heroImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const heroBanner: SectionHeroBanner = markdownRemark.frontmatter;
  const img = file.childImageSharp.fluid;

  return (
    <Banner
      title={heroBanner.title}
      subtitle={heroBanner.subtitle}
      content={heroBanner.content}
      callToAction={heroBanner.callToAction}
      callToActionSubtitle={heroBanner.callToActionSubtitle}
      linkTo={heroBanner.linkTo}
      linkText={heroBanner.linkText}
      appImage={img}
    />
  );
};

export default HeroBanner;
